<template>
    <div class="title_page">
        <div class="title" :class="type == 2 ?'titleColor':''">
            {{title}}
        </div>
        <img v-if="type == 1" src="@/assets/img/homes/01.png" alt="">
        <img v-if="type == 2" src="@/assets/img/homes/02.png" alt="">
        <img v-if="type == 3" src="@/assets/img/homes/03.png" alt="">
        
    </div>
</template>
<script>
export default {
    props:{
        title:{},
        type:{}
    },
    data(){
        return{}
    }
}
</script>
<style lang="scss" scoped>




    .title_page{
       
        height: 84px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .title{
            font-size: 30px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            position: absolute;
           
        }
        .titleColor{
            color: white !important
        }
        img{
            width: 90px;
            
        }
        
    }
</style>