<template>
    <div class="home_title_page display column">
        <div class="title_name" :class="color == 1 ?'colors':''" style="background:color">{{ titleName
        }}</div>
        <img class="icon_line" src="@/assets/img/homes/icon_line.png" alt="">
    </div>
</template>
<script>
export default {
    props:{
        titleName:{},
        color:{}
    },
    data(){
        return{}
    }
}
</script>
<style lang="scss" scoped>
    .home_title_page{
        padding-top: 100px;
        .title_name{
            font-size: 40px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            line-height: 40px;
        }
        .colors{
            color: white !important;
        }
        .icon_line{
            width: 212px;
            height: 3px;
            margin-top: 16px;
        }
    }
</style>