<template>
    <div class="home_page">
        <Banner :adCategory="1" :big="1"></Banner>
        <div class="module1">
            <div class="content">
                <div class="serve_num_box Around">
                    <div class="list">
                        <div class="serve_num display">
                            <div class="num">{{ formData.buildingProtection }}</div>
                            <div class="unit">年</div>
                        </div>
                        <div class="serve">精耕建筑防护行业</div>
                        <div class="line"></div>
                    </div>
                    <div class="list">
                        <div class="serve_num display">
                            <div class="num">{{ formData.servedFortune500 }}</div>
                            <div class="unit">家</div>
                        </div>
                        <div class="serve">已服务世界 500 强企业</div>
                        <div class="line"></div>
                    </div>
                    <div class="list">
                        <div class="serve_num display">
                            <div class="num">{{ formData.top500Domestic }}</div>
                            <div class="unit">家</div>
                        </div>
                        <div class="serve">已服务国内 500 强企业</div>
                        <div class="line"></div>
                    </div>
                    <div class="list">
                        <div class="serve_num display">
                            <div class="num">{{ formData.projectCaseNum }}</div>
                            <div class="unit">+</div>
                        </div>
                        <div class="serve">无忧工程案例</div>
                    </div>
                </div>
                <HomeTitle titleName="服务范围"></HomeTitle>
                <div class="accordion">
                    <div class="or-container">
                        <div class="list pointer" :class="eleindex == i ? 'eleactive' : ''"
                            v-for="(ele, i) in formData.serviceAreaCategoryVOS" :key="i" @mouseenter="enter(i)"
                            @click="toServe(ele)">
                            <img :src="ele.imgUrl" />
                            <div class="title display">{{ ele.categoryName }}</div>
                            <div v-if="eleindex != i" class="maskLayer"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module2">
            <div class="bg"></div>
            <div class="content module2_content">
                <HomeTitle titleName="预约免费上门勘测" :color="1"></HomeTitle>
                <div class="formData display column">
                    <div class="formData_div flex">
                        <!-- <div class="list display">
                            <input type="text" v-model="makeAppointment.companyName" placeholder="请输入公司名称" />
                        </div>
                        <div class="list display">
                            <el-cascader size="large" :options="options" :props="props" v-model="AreaArray"
                                @change="selectArea" placeholder="请选择所在城市" clearable></el-cascader>
                        </div>
                        <div class="list display">
                            <el-select @change="selectType" v-model="makeAppointment.categoryName" clearable
                                placeholder="请选择需求类型">
                                <el-option v-for="item in $store.state.serviceType" :key="item.value" :label="item.label"
                                    :value="item">
                                </el-option>
                            </el-select>
                        </div> -->
                    </div>
                    <div class="formData_div1 flex">
                        <div class="list list1 display">
                            <input type="text" placeholder="请输入姓名" v-model="makeAppointment.userName" />
                        </div>
                        <div class="list list2 display">
                            <input type="text" maxlength="11" placeholder="请输入手机号"
                                v-model="makeAppointment.phoneNumber" />
                        </div>
                    </div>
                    <div class="btn display pointer" @click="signUp">免费预约</div>
                </div>
                <div class="video_div" v-if="videoShow == true">
                    <img @click.stop="videoShow = false" class="off pointer" src="@/assets/img/off.png" alt="" />
                    <video :poster="formData.reserveBackgroundImage" :src="formData.reserveBackgroundVideoUrl"
                        class="icon_img pointer" id="myVideo" controls playsinline="true" autoplay></video>
                </div>
                <img v-else @click="startBtn" class="icon_img pointer" :src="formData.reserveBackgroundImage" alt="" />

                <HomeTitle titleName="解决方案"></HomeTitle>
                <div class="solution_box display">
                    <div class="list display column pointer" :class="solutionIndex == index ? 'activeSolution' : ''"
                        v-for="(item, index) in formData.solutionVOS" :key="index" @click="changeSolution(item, index)">
                        <img :src="solutionIndex == index ? item.highlightIcon : item.icon" alt="" />
                        <div class="name">{{ item.solutionName }}</div>
                        <div class="icon_triangle" v-if="solutionIndex == index"></div>
                    </div>
                </div>
                <div class="solution_cont display">
                    <div class="solution_list display pointer" :class="solutionIndexs == index ? 'active' : ''"
                        v-for="(item, index) in solutionList" :key="index" @click="changePlanBtn(item, index)">
                        {{ item.solutionName }}
                        <div class="line"></div>
                    </div>
                </div>
                <div class="plan_box">
                    <img :src="solutionData.icon" alt="" />
                    <div class="plan_details">
                        <div class="maskLayer"></div>
                        <div class="solution Between">
                            <p class="text">
                                {{ solutionData.briefIntroduce }}
                            </p>
                            <div class="learnMore display pointer" @click="toSolution">
                                <span>了解更多</span>
                                <img class="icon_more" src="@/assets/img/homes/more.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module3">
            <HomeTitle titleName="核心优势"></HomeTitle>
            <div class="title1">
                <Title :title="coreData.coreAdvantagesName" :type="1"></Title>
            </div>
            <div class="main">
                <!-- <el-carousel :autoplay="false">
                    <el-carousel-item class="el-car-item" v-for="(list, index) in formData.honorVOS" :key="index">
                        <div class="carouselData flex-center column" v-for="(imgList,index2) in list" :key="index2">
                            <img class="certificate" :src="imgList.honorUrl" :alt="imgList.introduce" />
                            <div class="name">{{ imgList.introduce }}</div>
                        </div>
                    </el-carousel-item>
                </el-carousel> -->
                <div class="cont_div">
                    {{ coreData.coreAdvantagesIntroduction }}
                </div>
                <div class="honor_box Around">
                    <div class="line"></div>
                    <div class="list flex-center column pointer" v-for="(item, index) in formData.homeCoreAdvantagesVOS"
                        :key="index" @mouseover="moveSeover(item, index)">
                        <div class="honor_list_div display" v-if="coreIndex == index">
                            <img class="icon_img" src="@/assets/img/homes/isShow.png" alt="" />
                            <div class="name">{{ item.coreAdvantagesName }}</div>
                            <img class="icon_d" src="@/assets/img/homes/icon_yes.png" alt="" />
                        </div>
                        <div class="names" v-else>{{ item.coreAdvantagesName }}</div>

                        <img v-if="coreIndex != index" class="icon_imgs" src="@/assets/img/homes/icon_no.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
        <div class="module4">
            <div class="bg"></div>

            <div class="content">
                <div class="title2">
                    <Title :title="'无忧工程—我生产 · 我施工 · 我负责'" :type="2"></Title>
                </div>
                <div class="carefree display">
                    我生产-材料无忧&nbsp; · &nbsp;我施工-施工无忧&nbsp; ·
                    &nbsp;我负责-服务无忧
                </div>
                <div class="carefree_content">
                    <div class="title display">
                        <div class="black_title">我生产-</div>
                        <div class="red_title">材料无忧</div>
                    </div>
                    <div class="materials flex">
                        <div class="materials_list flex-center column" v-for="(item, index) in materialsLIst.value"
                            :key="index">
                            <img class="icon_img" :src="item.highlightWorryFreeImage" alt="" />
                            <img class="icon_imgs" :src="item.worryFreeImage" alt="" />
                            <div class="name">{{ item.worryFreeName }}</div>
                            <div class="line"></div>
                            <div class="cont display">{{ item.worryFreeIntroduce }}</div>
                        </div>
                    </div>
                    <div class="construction">
                        <div class="title display">
                            <div class="black_title">我施工-</div>
                            <div class="red_title">施工无忧</div>
                        </div>
                        <NavBar :navBarList="constructionList.value" @changeNavBar="changeNavBar"></NavBar>
                        <div class="slideshow">
                            <el-carousel indicator-position="outside" :interval="3000">
                                <el-carousel-item class="el-car-item" v-for="(item, index) in constructionData"
                                    :key="index">
                                    <div class="carouselData display">
                                        <img class="certificate" :src="item.worryFreeImage" :alt="item.worryFreeName" />
                                    </div>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                        <div class="title display">
                            <div class="black_title">我负责-</div>
                            <div class="red_title">售后无忧</div>
                        </div>
                        <div class="afterSale flex">
                            <div class="afterSaleList flex-center column" v-for="(item, index) in afterSaleList.value"
                                :key="index">
                                <img :src="item.worryFreeImage" alt="" />
                                <div class="titleName">{{ item.worryFreeName }}</div>
                                <div class="cont">{{ item.worryFreeIntroduce }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg_bottom"></div>
            </div>
        </div>
        <div class="module5">
            <div class="content">
                <Title :title="'客户评价—用心服务'" :type="3"></Title>
                <el-carousel :autoplay="false">
                    <el-carousel-item class="el-car-item" v-for="(item, index) in formData.homeCustomerReviewsVOS"
                        :key="index">
                        <div class="serveBox flex">
                            <img class="coverUrl" :src="item.cover" alt="" />
                            <div class="serveDetails">
                                <div class="title">{{ item.reviewsName }}</div>
                                <div class="serve_cont">{{ item.reviewsContent }}</div>
                                <div class="serveUrl flex">
                                    <div class="fl">
                                        <img :src="item.imgUrls[0]" alt="" v-if="item.imgUrls.length >= 1" />
                                    </div>
                                    <div class="fr">
                                        <img :src="item.imgUrls[1]" alt="" v-if="item.imgUrls.length >= 2" />
                                        <img :src="item.imgUrls[2]" alt="" v-if="item.imgUrls.length >= 3" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div class="module6">
            <HomeTitle titleName="工程案例"></HomeTitle>
            <img class="icon_bg" :src="formData.engineeringCaseBackgroundImage" alt="" />
        </div>
        <div class="module7">
            <div class="content">
                <NavBar :navBarList="formData.projectCaseCategoryVOS" @changeNavBar="changeCaseNavBar"></NavBar>
                <ProjectCaseList :companyIndex="companyIndex" :item="companyList" :companyList="companyList.value">
                </ProjectCaseList>
                <div class="more display pointer" v-if="companyList.length != 0" @click="viewBtn">
                    查看更多
                </div>
            </div>
        </div>
        <div class="module8">
            <div class="content">
                <HomeTitle titleName="产品中心"></HomeTitle>
                <div class="product_main flex">
                    <div class="product_fl">
                        <div class="environmental">
                            <div class="protective">
                                1000+系列产品，满足建筑全方位防护需求
                            </div>
                            <div class="green_environmental">绿色环保，经久耐用</div>
                        </div>
                        <div class="classification">
                            <div class="classification_list" v-for="(item, index) in classification" :key="index"
                                @click="changeBtn(item, index)">
                                <div class="name pointer">{{ item.label }}</div>
                                <div class="line" v-if="classificationIndex == index"></div>
                            </div>
                        </div>
                    </div>
                    <div class="product_fr">
                        <img class="product_img pointer" :src="classificationData.marketingImage" alt=""
                            @click="toGoods" />
                        <div class="up_down">
                            <div class="mask_layer"></div>
                            <div class="cont Between">
                                <img class="pointer" @click="upDown(0)" src="@/assets/img/homes/fl.png" alt="" />
                                <div class="name">{{ classificationData.label }}</div>
                                <img class="pointer" @click="upDown(1)" src="@/assets/img/homes/fr.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module9">
            <HomeTitle titleName="服务流程"></HomeTitle>
            <div class="content">
                <div class="service_process display" v-if="formData.homeServiceProcessVOS">
                    <div class="list display column" v-for="(item, index) in formData.homeServiceProcessVOS"
                        :key="index">
                        <img class="icpn_img" :src="item.processImage" alt="" />
                        <img class="icpn_arrows" src="@/assets/img/homes/arrows.png" alt="" />
                        <div class="name">{{ item.processName }}</div>
                    </div>
                </div>
                <div class="appointment display pointer" @click="$store.state.isShow = true">
                    立即预约
                </div>
            </div>
        </div>
        <div class="module10">
            <div class="content">
                <HomeTitle titleName="关于我们" :color="1"></HomeTitle>
                <div class="about_us">
                    <div class="title">{{ formData.homeAboutUsVO.companyName }}</div>
                    <div class="rich_text" v-html="formData.homeAboutUsVO.content"></div>
                    <div class="swiper_cont">
                        <swiper class="swiper" ref="swiper" :options="swiperOption">
                            <swiper-slide class="slide" v-for="item in formData.homeAboutUsVO.imgUrls" :key="item.id">
                                <img class="swiper_img" :src="item" alt="" />
                            </swiper-slide>
                            <div class="swiper-button-prev" slot="button-prev">
                                <img class="icon_left_right" src="@/assets/img/homes/left.png" alt="" />
                            </div>
                            <div class="swiper-button-next" slot="button-next">
                                <img class="icon_left_right" src="@/assets/img/homes/right.png" alt="" />
                            </div>
                        </swiper>
                    </div>
                    <div class="learnMore display pointer" @click="aboutUs">了解更多</div>
                </div>
            </div>
        </div>
        <div class="module11">
            <div class="content">
                <HomeTitle titleName="新闻资讯"></HomeTitle>
                <!-- <NavBar :navBarList="formData.newsVOS" @changeNavBar="changeNewsNavBar"></NavBar>
                 -->
                <div class="navBar_page">
                    <div class="navBar display">
                        <div class="navBar_list display pointer" v-for="(item, index) in formData.newsVOS" :key="index"
                            @click="changeNewsNavBar(item, index)" :class="navBarIndex == index ? 'navBarActive' : ''">
                            {{ item.name }}
                        </div>
                    </div>
                </div>
                <NewsList :newsList="newsList.value" :fatherId="newsList.index + 1" v-if="newsList.name != '视频中心'">
                </NewsList>
                <div class="videoList_box" v-else>
                    <div class="classify_box display">
                        <div class="classify_list pointer display"
                            :class="classifyIndex == index ? 'classifyActive' : ''"
                            v-for="(item, index) in classifyList" :key="index" @click="changeNavBtn(item, index)">
                            {{ item.name }}
                        </div>
                    </div>
                    <div class="video_mian">
                        <VideoList :videoList="videoList"></VideoList>
                    </div>
                </div>

                <div @click="newsViewBtn" class="view_more display pointer">
                    查看更多
                </div>
            </div>
        </div>
        <div class="module12">
            <div class="content">
                <HomeTitle titleName="合作伙伴"></HomeTitle>
                <div class="carousel_box">
                    <el-carousel :autoplay="false">
                        <el-carousel-item v-for="(item, index) in formData.homeCooperationCompanyVOS" :key="index">
                            <div class="el_carousel_cont flex flex-wrap">
                                <div class="list" v-for="(imgList, index2) in item" :key="index2">
                                    <img class="pointer" :src="imgList.imgUrl" alt="" />
                                </div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
        <!-- <div class="make_appointment" v-if="$store.state.isShow">
            <div class="mask"></div>
            <div class="make_appointment_content">
                <img class="icon_close pointer" @click="$store.state.isShow = false" src="@/assets/img/close.png" alt="">
                <div class="title display">免费预约</div>
                <div class="main">
                    <div class="list display">
                        <input type="text" placeholder="请输入公司名称" v-model="makeAppointment.companyName">
                    </div>
                    <div class="list display">
                        <el-cascader size="large" :options="options" :props="props" v-model="AreaArray"
                            @change="selectArea" placeholder="请选择所在城市" clearable></el-cascader>
                    </div>
                    <div class="list display">
                        <el-select @change="selectType" v-model="makeAppointment.categoryName" clearable
                            placeholder="请选择需求类型">
                            <el-option v-for="item in $store.state.serviceType" :key="item.value" :label="item.label"
                                :value="item">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="list Between user_phone">
                        <input class="min_input" type="text" placeholder="请输入姓名" v-model="makeAppointment.userName">
                        <input class="min_input" type="text" maxlength="11" placeholder="请输入手机号"
                            v-model="makeAppointment.phoneNumber">
                    </div>
                    <div class=" btn display pointer" @click="signUp">立即预约
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="video_mask_layer" v-if="videoShow == true">
            <div class="mask_layer">
            </div>
            <div class="video_cont">
                <img class="offBtn pointer" @click="videoShow = false" src="@/assets/img/homes/off.png" alt="">
                <video class="videoCont" :src="formData.reserveBackgroundVideoUrl" width="100%" controls></video>
            </div>
        </div> -->
    </div>
</template>
<script>
import Banner from '@/components/banner'
import HomeTitle from '@/components/homeTItle'
import Title from '@/components/title'
import NavBar from '@/components/navBar'
import NewsList from '@/components/newsList'
import VideoList from './news/components/videoList'
import ProjectCaseList from '@/components/projectCaseList'
import areaJSON from '@/assets/js/area.json'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
    components: {
        Banner,
        Title,
        NavBar,
        HomeTitle,
        Swiper,
        SwiperSlide,
        ProjectCaseList,
        NewsList,
        VideoList,
    },
    data() {
        return {
            coreIndex: 0,
            coreData: {},
            videoShow: false,
            isShow: false,
            solutionIndex: 0,
            navBarIndex: 0,
            classificationIndex: 0,
            companyIndex: null,
            value: null,
            eleindex: 4,
            options: areaJSON,
            AreaArray: [],
            props: {
                children: 'children',
                label: 'name',
                value: 'name',
            },
            solutionData: {},
            materialsLIst: [],
            navBarList: [
                {
                    id: 1,
                    name: '资质篇',
                },
                {
                    id: 2,
                    name: '安全篇',
                },
                {
                    id: 3,
                    name: '队伍篇',
                },
                {
                    id: 4,
                    name: '工艺篇',
                },
            ],
            constructionList: [],
            constructionData: [],
            classifyIndex: 0,
            afterSaleList: [{}, {}, {}],
            classifyList: [
                {
                    id: 1,
                    name: '企业视频',
                },
                {
                    id: 2,
                    name: '产品介绍',
                },
                {
                    id: 3,
                    name: '解决方案',
                },
                {
                    id: 4,
                    name: '工程案例',
                },
            ],
            videoList: [],
            paramsVideo: {
                page: 1,
                limit: 4,
                videoType: 1,
            },
            classification: [],
            classificationData: {},
            swiperOption: {
                slidesPerView: 4,
                spaceBetween: 20,
                direction: 'horizontal',
                observer: true,
                observeParents: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on: {
                    resize: () => {
                        this.$refs.swiper.$swiper.changeDirection(
                            window.innerWidth <= 1200 ? 'vertical' : 'horizontal'
                        )
                    },
                },
            },
            newsList: [],
            formData: {
                homeAboutUsVO: {
                    companyName: '',
                    content: '',
                    imgUrls: '',
                },
            },
            companyList: [],
            makeAppointment: {
                serviceAreaCategoryId: 0,
                categoryName: '-',
                userName: '',
                phoneNumber: '',
                companyName: '-',
                province: '-',
                city: '-',
                district: '-',
                detailAddress: 0,
                detailedRequirements: 0,
            },
            solutionList: [],
            solutionIndexs: 0,
        }
    },
    created() {
        this.getData()
        this.getGoodsData()
    },
    methods: {
        //获取首页数据
        getData() {
            this.api.findHomeData().then((res) => {
                if (res.code == 0) {
                    this.formData = res.data
                    if (this.formData.serviceAreaCategoryVOS.length != 0) {
                        this.eleindex = Math.floor(
                            this.formData.serviceAreaCategoryVOS.length / 2
                        )
                        if (this.formData.serviceAreaCategoryVOS.length == 1) {
                            this.eleindex = 1
                        }
                    }
                    if (this.formData.solutionVOS.length != 0) {
                        this.solutionData = this.formData.solutionVOS[0].solutionVOS[0]
                        this.solutionList = this.formData.solutionVOS[0].solutionVOS
                    }
                    if (this.formData.honorVOS.length != 0) {
                        this.formData.honorVOS = this.init(this.formData.honorVOS, 4)
                    }
                    if (this.formData.homeCoreAdvantagesVOS) {
                        this.coreData = this.formData.homeCoreAdvantagesVOS[0]
                    }
                    this.materialsLIst = this.formData.homeWorryFreeVOS[0]
                    this.constructionList = this.formData.homeWorryFreeVOS[1]
                    this.constructionData = this.constructionList.value[0].value

                    this.afterSaleList = this.formData.homeWorryFreeVOS[2]
                    this.companyList = this.formData.projectCaseCategoryVOS[0]
                    this.newsList = this.formData.newsVOS[0]
                    this.newsList.value.forEach((item) => {
                        item.createTime = item.createTime.slice(0, 10)
                    })
                    this.newsList.index = 0
                    this.formData.homeCooperationCompanyVOS = this.init(
                        this.formData.homeCooperationCompanyVOS,
                        24
                    )
                }
            })
        },
        // 获取产品中心数据
        getGoodsData() {
            this.api.findGoodsClassification().then((res) => {
                console.log(res)
                this.classification = res.data
                this.classificationData = this.classification[0]
            })
        },
        upDown(num) {
            if (num == 0) {
                if (this.classificationIndex == 0) {
                    this.classificationIndex = 0
                } else {
                    this.classificationIndex--
                }
            } else {
                let num = this.classification.length - 1
                if (this.classificationIndex == num) {
                    this.classificationIndex = num
                } else {
                    this.classificationIndex++
                }
            }
            this.classificationData = this.classification[this.classificationIndex]
        },

        // 轮播图格式化
        init(dataList, num) {
            let newDataList = []
            let current = 0
            if (dataList && dataList.length > 0) {
                for (let i = 0; i <= dataList.length - 1; i++) {
                    if (i % num !== 0 || i === 0) {
                        if (!newDataList[current]) {
                            newDataList.push([dataList[i]])
                        } else {
                            newDataList[current].push(dataList[i])
                        }
                    } else {
                        current++
                        newDataList.push([dataList[i]])
                    }
                }
            }
            return (dataList = [...newDataList])
        },
        enter(i) {
            console.log(i)
            this.eleindex = i
            // if (this.eleindex === i) {
            //   this.eleindex = -1
            // } else {
            //   this.eleindex = i
            // }
        },
        // 选择省市区
        selectArea(val) {
            this.makeAppointment.province = val[0]
            this.makeAppointment.city = val[1]
            this.makeAppointment.district = val[2]
        },
        // 解决方案的移入
        mouseOver(index) {
            this.solutionIndex = index
        },
        // 选择解决方案
        changeSolution(item, index) {
            this.solutionIndex = index
            this.solutionIndexs = 0
            this.solutionData = item.solutionVOS[0] || []
            this.solutionList = item.solutionVOS
        },

        //点击产品中心
        changeBtn(item, index) {
            this.classificationIndex = index
            this.classificationData = item
            console.log(this.classificationData)
        },
        //点击施工无忧分类
        changeNavBar(item) {
            console.log(item)
            this.constructionData = item.value
        },
        //点击工程案例分类
        changeCaseNavBar(item, index) {
            this.companyList = item
            this.companyIndex = index
        },
        //点击新闻资讯分类
        changeNewsNavBar(item, index) {
            console.log(item)
            if (item.name == '视频中心') {
                this.getVideoData()
            } else {
                item.value.forEach((data) => {
                    data.createTime = data.createTime.slice(0, 10)
                })
            }
            item.index = index
            this.navBarIndex = index
            this.newsList = item
            console.log(item, index)
        },
        //选择服务呢类型分类
        selectType(val) {
            console.log(val)
            this.makeAppointment.categoryName = val.label
            this.makeAppointment.serviceAreaCategoryId = val.value
        },
        // 立即报名
        signUp() {
            // if (this.makeAppointment.companyName == '') {
            //     return this.$message.error('请输入公司名称')
            // }
            // if (this.makeAppointment.province == '') {
            //     return this.$message.error('请选择所在城市')
            // }
            // if (this.makeAppointment.categoryName == '') {
            //     return this.$message.error('请选择需求类型')
            // }
            if (this.makeAppointment.userName == '') {
                return this.$message.error('请输入姓名')
            }
            if (!/^[0-9]{11}$/.test(this.makeAppointment.phoneNumber)) {
                return this.$message.error('请正确输入手机号')
            }
            this.api.addReserve(this.makeAppointment).then((res) => {
                if (res.code == 0) {
                    this.$message.success('提交成功')
                    this.makeAppointment = {
                        serviceAreaCategoryId: 0,
                        categoryName: '-',
                        userName: '',
                        phoneNumber: '',
                        companyName: '-',
                        province: '-',
                        city: '-',
                        district: '-',
                        detailAddress: 0,
                        detailedRequirements: 0,
                    }
                    this.AreaArray = []
                    this.$store.state.isShow = false
                }
            })
        },
        getVideoData() {
            this.api.findPageVideo(this.paramsVideo).then((res) => {
                console.log(res)
                this.videoList = res.data
                this.total = res.count
            })
        },
        changeNavBtn(item, index) {
            this.paramsVideo.videoType = item.id
            this.classifyIndex = index
            this.getVideoData()
        },
        toServe(item) {
            let obj = {
                ids: 2,
                id: item.id,
                name: item.categoryName,
                index: this.eleindex,
                fatherName: '',
            }
            this.$store.commit('getTypeId', item.id)
            localStorage.setItem('navBarData', JSON.stringify(obj))
            let routeData = this.$router.resolve({
                path: '/scopeServices',
                query: {
                    ids: 2,
                    id: item.id,
                    name: item.categoryName,
                    index: this.eleindex,
                },
            })
            window.open(routeData.href, '_blank')
        },
        //点击跳转产品中心
        toGoods() {
            let obj = {
                ids: 5,
                id: this.classificationData.id,
                name: this.classificationData.label,
                index: this.classificationIndex,
                fatherName: '',
                isReturn: 3,
            }
            this.$store.commit('getTypeId', this.classificationData.id)
            localStorage.removeItem('isService')
            localStorage.setItem('navBarData', JSON.stringify(obj))
            let routeData = this.$router.resolve({
                path: '/productCenter',
                query: obj,
            })
            window.open(routeData.href, '_blank')
        },
        //点击跳转解决方案
        toSolution() {
            console.log(this.solutionIndex)
            let fatherName = ''
            if (this.solutionIndex == 0) {
                fatherName = '新建筑'
            } else {
                fatherName = '旧建筑'
            }
            let obj = {
                ids: 3,
                id: this.solutionData.id,
                name: this.solutionData.solutionName,
                fatherName: fatherName,
                isReturn: 3,
            }
            this.$store.commit('getTypeId', this.solutionData.id)
            localStorage.setItem('navBarData', JSON.stringify(obj))
            let routeData = this.$router.resolve({
                path: '/solution',
                query: obj,
            })
            window.open(routeData.href, '_blank')
        },
        //点击跳转关于我们
        aboutUs() {
            console.log(this.solutionData)
            let routeData = this.$router.resolve({
                path: '/aboutUs',
                query: {
                    ids: 7,
                    isReturn: 3,
                    name: '关于我们',
                },
            })
            window.open(routeData.href, '_blank')
        },
        //点击跳转
        viewBtn() {
            let obj = {
                ids: 4,
                id: this.companyList.id,
                name: this.companyList.name,
                index: this.companyIndex,
                fatherName: '',
                isReturn: 3,
            }
            this.$store.commit('getTypeId', this.companyList.id)
            localStorage.setItem('navBarData', JSON.stringify(obj))
            let routeData = this.$router.resolve({
                path: '/projectCase',
                query: obj,
            })
            window.open(routeData.href, '_blank')
        },
        newsViewBtn() {
            let obj = {
                ids: 6,
                id: this.newsList.index + 1,
                name: this.newsList.name,
                index: this.newsList.index,
                fatherName: '',
                isReturn: 3,
                classifyIndex: this.classifyIndex,
            }
            this.$store.commit('getTypeId', this.newsList.index + 1)
            localStorage.setItem('navBarData', JSON.stringify(obj))
            let routeData = this.$router.resolve({
                path: '/news/index',
                query: obj,
            })
            window.open(routeData.href, '_blank')
        },
        startBtn() {
            this.videoShow = true
            let myVideo = document.getElementById('myVideo')
            myVideo.play()
        },
        // 核心优势移入
        moveSeover(item, index) {
            this.coreData = item
            this.coreIndex = index
        },
        changePlanBtn(item, index) {
            console.log(item)
            this.solutionData = item
            this.solutionIndexs = index
        },
    },
}
</script>
<style lang="scss" scoped>
.home_page {
    .module1 {
        width: 100%;
        height: 845px;
        background: url('../assets/img/homes/bg1.png') no-repeat;
        background-size: 100% 100%;
        position: relative;
        padding-top: 100px;

        .serve_num_box {
            width: 1200px;
            height: 150px;
            background: #ffffff;
            box-shadow: 0px 1px 5px 0px rgba(64, 64, 64, 0.25);
            border-radius: 5px;
            position: absolute;
            top: -53px;
            z-index: 8;

            .list {
                position: relative;

                .line {
                    width: 1px;
                    height: 96px;
                    background: #f5f5f5;
                    position: absolute;
                    top: 0px;
                    right: -55px;
                }

                .serve_num {
                    .num {
                        font-size: 54px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #e62129;
                        line-height: 44px;
                    }

                    .unit {
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #e62129;
                        line-height: 18px;
                        margin-top: 20px;
                        margin-left: 12px;
                    }
                }

                .serve {
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    line-height: 18px;
                    margin-top: 23px;
                    text-align: center;
                }
            }
        }

        .accordion {
            margin-top: 60px;

            .or-container {
                display: flex;
                width: 100%;
                box-sizing: border-box;
                height: 430px;
            }

            .or-container:before {
                background-color: rgba(0, 0, 0, 0.4);
            }

            .list {
                flex: 1;
                overflow: hidden;
                transition: 0.5s;
                box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
                line-height: 0;
                position: relative;
                border-right: 1px solid #fff;

                .maskLayer {
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.48);
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    z-index: 1;
                }
            }

            .list:last-child() {
                border-right: 0 !important;
            }

            .list>img {
                width: 100%;
                height: 100%;
                -o-object-fit: cover;
                object-fit: cover;
                transition: 0.5s;
            }

            .list>.title {
                width: 100%;
                height: 50px;
                background: #000;
                position: absolute;
                left: 0;
                bottom: 0;
                color: white;
                font-size: 16px;
            }

            .eleactive {
                flex: 1 1 40%;
            }

            .eleactive>img {
                width: 100%;
                height: 100%;
            }

            .eleactive>.title {
                width: 100%;
                height: 50px;
                background: #e62129 !important;
                position: absolute;
                left: 0;
                bottom: 0;
                color: white;
                z-index: 999;
                font-weight: bold;
                font-size: 20px;
            }
        }
    }

    .module2 {
        background: white;

        .bg {
            width: 100%;
            height: 662px;
            background: url('../assets/img/homes/bg2.png') no-repeat;
            background-size: 100% 100%;
        }

        .module2_content {
            margin-top: -662px;
            padding-bottom: 100px;

            .formData {
                margin-top: 40px;

                .formData_div {
                    .list {
                        width: 244px;
                        height: 46px;
                        background: #f5f5f5;
                        margin-left: 10px;

                        input {
                            width: 240px;
                            height: 44px;
                            background: #f5f5f5;
                            padding-left: 18px;
                        }

                        ::v-deep.el-input__inner {
                            background: #f5f5f5 !important;
                            border: none;
                            width: 240px;
                            height: 46px;
                        }
                    }
                }

                .formData_div1 {
                    .list {
                        width: 371px;
                        height: 46px;
                        background: #f5f5f5;
                        margin-top: 20px;
                        margin-left: 10px;

                        input {
                            width: 100%;
                            height: 44px;
                            background: #f5f5f5;
                            padding-left: 20px;
                        }
                    }
                }

                .btn {
                    width: 752px;
                    height: 50px;
                    background: #e62129;
                    margin-left: 10px;
                    margin-top: 18px;
                    font-size: 18px;
                    color: #ffffff;
                }
            }

            .icon_img {
                width: 1200px;
                height: 680px;
                margin-top: 40px;
            }

            .solution_box {
                margin-top: 40px;

                .list {
                    width: 150px;
                    height: 98px;
                    border: 1px solid #dedede;
                    margin: 0px 2px;
                    position: relative;

                    img {
                        width: 37px;
                        height: 37px;
                    }

                    .name {
                        font-size: 16px;
                        color: #333333;
                        margin-top: 14px;
                        line-height: 16px;
                    }

                    .icon_triangle {
                        border: 10px solid transparent;
                        border-top: 10px solid #e62129;
                        position: absolute;
                        left: 50%;
                        bottom: -20px;
                        margin-left: -8px;
                    }
                }

                .activeSolution {
                    background: #e62129;
                    border: 1px solid #e62129;

                    .name {
                        color: white;
                    }
                }
            }

            .solution_cont {
                margin-top: 24px;

                .solution_list {
                    width: 126px;
                    height: 20px;
                    font-size: 16px;
                    color: #333;
                    position: relative;

                    .line {
                        width: 1px;
                        height: 20px;
                        background: #dedede;
                        position: absolute;
                        top: 4px;
                        right: 0;
                    }
                }

                .active {
                    color: #e62129 !important;
                }
            }

            .solution_list:last-child {
                .line {
                    display: none;
                }
            }

            .plan_box {
                position: relative;
                margin-top: 24px;

                img {
                    width: 1200px;
                    height: 600px;
                }

                .plan_details {
                    width: 100%;
                    height: 125px;
                    position: absolute;
                    left: 0;
                    bottom: 0;

                    .maskLayer {
                        width: 100%;
                        height: 121px;
                        background: #000;
                        opacity: 0.6;
                    }
                }

                .solution {
                    width: 100%;
                    height: 121px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    padding-left: 54px;
                    padding-right: 39px;

                    .text {
                        width: 823px;
                        height: 58px;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 20px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }

                    .learnMore {
                        width: 180px;
                        height: 50px;
                        background: #e62129;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #ffffff;
                        margin-top: 15px;

                        .icon_more {
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            margin-left: 8px;
                        }
                    }
                }
            }
        }
    }

    .module3 {
        width: 100%;
        height: 690px;
        background: url('../assets/img/homes/bg4.png') no-repeat;
        background-size: 100% 100%;

        .title1 {
            margin-top: 26px;
            margin-bottom: 26px;
        }

        .main {
            .cont_div {
                width: 800px;
                font-size: 18px;
                color: #333333;
                line-height: 24px;
                margin: 0 auto;
                text-align: center;
            }

            .honor_box {
                width: 720px;
                margin: 0 auto;
                margin-top: 100px;
                position: relative;

                .line {
                    width: 720px;
                    height: 1px;
                    margin: 0 auto;
                    background: url('../assets/img/homes/line.png') no-repeat;
                    background-size: 100% 100%;
                    position: absolute;
                    bottom: 0px;
                }

                .list {
                    width: 110px;

                    .honor_list_div {
                        width: 100px;
                        height: 110px;
                        position: relative;

                        .icon_img {
                            width: 100px;
                            height: 100%;
                            margin-bottom: 26px;
                        }

                        .name {
                            font-size: 18px;
                            color: #ffffff;
                            position: absolute;
                            top: 25px;
                        }

                        .icon_d {
                            width: 13px;
                            height: 13px;
                            position: absolute;
                            bottom: -6px;
                        }
                    }

                    .names {
                        height: 48px;
                        font-size: 18px;
                        color: #333333;
                    }

                    .icon_imgs {
                        width: 13px;
                        height: 13px;
                        margin-bottom: -63px;
                        z-index: 999;
                    }
                }
            }
        }
    }

    .module4 {
        height: 2260px;
        background: #f4f5f5;
        padding-bottom: 145px;
        position: relative;

        .bg {
            width: 100%;
            height: 540px;
            background: url('../assets/img/homes/bg5.png') no-repeat;
            background-size: 100% 100%;
        }

        .bg_bottom {
            width: 100%;
            height: 406px;
            background: url('../assets/img/homes/bg9.png') no-repeat;
            background-size: 100% 100%;
            position: absolute;
            left: 0;
            bottom: 0;
        }

        .content {
            margin-top: -430px;
        }

        .carefree {
            width: 797px;
            height: 58px;
            border: 1px solid #ffffff;
            border-radius: 29px;
            margin: 0 auto;
            margin-top: 8px;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
        }

        .carefree_content {
            width: 1200px;
            margin-top: 110px;
            background: white;
            padding: 0px 32px;
            padding-bottom: 76px;
            box-sizing: border-box;
            position: absolute;
            left: 50%;
            margin-left: -600px;
            z-index: 9;

            .title {
                padding-top: 60px;

                div {
                    font-size: 30px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    line-height: 30px;
                }

                .black_title {}

                .red_title {
                    color: #e62129;
                    font-weight: bold;
                }
            }

            .materials {
                margin-top: 39px;

                .materials_list:nth-child(1) {
                    margin-left: 0px !important;
                }

                .materials_list:hover {
                    background: #e62129 !important;

                    .name {
                        color: white !important;
                    }

                    .line {
                        background: white !important;
                    }

                    .cont {
                        color: white !important;
                    }

                    .icon_imgs {
                        display: none;
                    }

                    .icon_img {
                        display: block;
                    }
                }

                .materials_list {
                    width: 260px;
                    height: 380px;
                    margin-left: 32px;
                    box-shadow: 0px 1px 5px 0px rgba(64, 64, 64, 0.25);

                    .icon_img {
                        width: 98px;
                        height: 98px;
                        margin-top: 50px;
                        display: none;
                    }

                    .icon_imgs {
                        width: 98px;
                        height: 98px;
                        margin-top: 50px;
                    }

                    .name {
                        font-size: 20px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #000000;
                        margin-top: 30px;
                        line-height: 20px;
                    }

                    .line {
                        width: 38px;
                        height: 2px;
                        background: #e62129;
                        margin-top: 20px;
                    }

                    .cont {
                        width: 190px;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #666666;
                        line-height: 24px;
                        margin-top: 23px;
                        text-align: center;
                    }
                }
            }

            .slideshow {
                margin-top: 32px;

                ::v-deep.el-car-item {
                    width: 100%;
                    display: flex;
                }

                ::v-deep.el-carousel {
                    .el-carousel__container {
                        height: 430px;

                        .el-carousel__arrow {
                            width: 60px;
                            height: 60px;
                            font-size: 24px;
                            background: rgba(51, 51, 51, 0.5);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }

                ::v-deep.el-carousel__indicators {
                    .el-carousel__button {
                        width: 6px;
                        height: 6px;
                        opacity: 1;
                        border-radius: 50%;
                        background: #5b5b5b;
                    }

                    .el-carousel__indicator.is-active button {
                        width: 26px;
                        height: 6px;
                        background: #e62129;
                        border-radius: 3px;
                    }
                }

                ::v-deep .el-carousel__arrow {
                    display: none !important;
                }

                .carouselData:nth-child(1) {
                    margin-left: 0px !important;
                }

                .carouselData {
                    width: 1200px;

                    img {
                        width: 1140px;
                        height: 420px;
                    }

                    .name {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #666666;
                        margin-top: 10px;
                    }
                }
            }

            .afterSale {
                margin-top: 47px;

                .afterSaleList:nth-child(1) {
                    margin-left: 0 !important;
                }

                .afterSaleList:hover {
                    border-bottom: 2px solid #e62129;
                    box-shadow: 0px 1px 5px 0px rgba(64, 64, 64, 0.25);

                    .titleName {
                        color: #e62129 !important;
                    }
                }

                .afterSaleList {
                    width: 360px;
                    height: 464px;
                    margin-left: 29px;

                    img {
                        width: 360px;
                        height: 280px;
                    }

                    .titleName {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                        line-height: 24px;
                        margin-top: 40px;
                    }

                    .cont {
                        width: 224px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #666666;
                        line-height: 24px;
                        text-align: center;
                        margin-top: 22px;
                    }
                }
            }
        }
    }

    .module5 {
        height: 750px;
        background: white;
        padding-top: 100px;

        ::v-deep.el-carousel {
            .el-carousel__container {
                height: 525px;

                .el-carousel__arrow {
                    width: 48px;
                    height: 48px;
                    font-size: 24px;
                    background: rgba(51, 51, 51, 0.5);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .serveBox {
            width: 1200px;
            height: 480px;
            background: #f4f5f5;
            margin-top: 26px;

            .coverUrl {
                width: 640px;
                height: 480px;
            }

            .serveDetails {
                padding-top: 43px;
                padding-left: 48px;
                box-sizing: border-box;

                .title {
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 20px;
                }

                .serve_cont {
                    width: 462px;
                    height: 37px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    line-height: 20px;
                    margin-top: 22px;
                    overflow: hidden;
                    overflow-y: scroll;
                }

                .serve_cont::-webkit-scrollbar {
                    display: none;
                }

                .serveUrl {
                    margin-top: 34px;

                    .fl {
                        img {
                            width: 198px;
                            height: 292px;
                            margin-right: 24px;
                        }
                    }

                    .fr {
                        img {
                            width: 260px;
                            height: 138px;
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }

    .module6 {
        .icon_bg {
            width: 100%;
            margin-top: 56px;
        }
    }

    .module7 {
        height: 843px;
        background: #f4f5f5;

        .content {
            padding-top: 42px;
        }

        .more {
            width: 250px;
            height: 48px;
            background: #e62129;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            margin: 0 auto;
            margin-top: 17px;
        }
    }

    .module8 {
        .product_main {
            margin-top: 60px;
            padding-bottom: 98px;

            .product_fl {
                width: 400px;
                height: 600px;
                background: url('../assets/img/homes/product.png') no-repeat;
                background-size: 100% 100%;
                position: relative;

                .environmental {
                    width: 474px;
                    height: 114px;
                    background: #e62129;
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    color: #ffffff;
                    line-height: 22px;
                    padding-top: 33px;
                    padding-left: 44px;
                    position: absolute;
                    top: 30px;
                    left: 30px;
                    z-index: 2;

                    .protective {
                        font-weight: bold;
                    }

                    .green_environmental {
                        margin-top: 16px;
                    }
                }

                .classification::-webkit-scrollbar {
                    display: none;
                }

                .classification {
                    width: 100%;
                    height: 555px;
                    overflow: hidden;
                    overflow-y: scroll;
                    padding-left: 93px;
                    box-sizing: border-box;
                    padding-top: 190px;

                    .classification_list {
                        height: 30px;
                        position: relative;

                        .name {
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                        }

                        .line {
                            width: 27px;
                            height: 1px;
                            background: #333333;
                            position: absolute;
                            top: 10px;
                            left: -35px;
                        }
                    }
                }
            }

            .product_fr {
                width: 800px;
                height: 600px;
                position: relative;

                .product_img {
                    width: 800px;
                    height: 600px;
                }

                .up_down {
                    width: 154px;
                    height: 52px;
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    margin-left: -77px;
                    z-index: 2;
                    padding: 0px 20px;
                    box-sizing: border-box;

                    .mask_layer {
                        width: 154px;
                        height: 52px;
                        opacity: 0.2;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        z-index: 2;
                        background: black;
                    }

                    .cont {
                        width: 154px;
                        height: 52px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 3;
                        padding: 0px 20px;
                        box-sizing: border-box;
                    }

                    img {
                        width: 6px;
                        height: 12px;
                        margin-top: 6px;
                    }

                    .name {
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                    }
                }
            }
        }
    }

    .module9 {
        height: 570px;
        background: url('../assets/img/homes/bg7.png') no-repeat;
        background-size: 100% 100%;

        .service_process {
            margin-top: 60px;

            .list:last-child {
                margin-right: 0px !important;

                .icpn_arrows {
                    display: none;
                }
            }

            .list {
                margin-right: 70px;
                position: relative;

                .icpn_img {
                    width: 80px;
                    height: 80px;
                }

                .icpn_arrows {
                    width: 24px;
                    height: 6px;
                    position: absolute;
                    right: -45px;
                    top: 40px;
                }

                .name {
                    font-size: 18px;
                    color: #333333;
                    line-height: 18px;
                    margin-top: 32px;
                }
            }
        }

        .appointment {
            width: 250px;
            height: 48px;
            background: #e62129;
            font-size: 18px;
            color: #ffffff;
            margin: 0 auto;
            margin-top: 72px;
        }
    }

    .module10 {
        min-width: 1200px;
        height: 794px;
        background: url('../assets/img/homes/bg8.png') no-repeat;
        background-size: 100% 100%;

        .content {
            position: relative;
        }

        .about_us {
            width: 1200px;
            background: #ffffff;
            box-shadow: 0px 1px 5px 0px rgba(64, 64, 64, 0.25);
            position: absolute;
            top: 270px;
            left: 0;
            padding: 60px 50px;
            box-sizing: border-box;

            .title {
                font-size: 24px;
                font-weight: bold;
                color: #333333;
                line-height: 28px;
                text-align: center;
            }

            .rich_text {
                width: 1100px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                line-height: 28px;
                margin-top: 16px;
            }

            .swiper_cont {
                padding-top: 36px;

                .swiper_img {
                    width: 259px;
                    height: 200px;
                }
            }

            .learnMore {
                width: 250px;
                height: 48px;
                background: #e62129;
                font-size: 18px;
                font-weight: 400;
                color: #ffffff;
                margin: 0 auto;
                margin-top: 33px;
            }
        }
    }

    .module11 {
        padding-top: 230px;
        padding-bottom: 102px;

        .view_more {
            width: 250px;
            height: 48px;
            background: #e62129;
            font-size: 18px;
            color: #ffffff;
            margin: 0 auto;
            margin-top: 58px;
        }
    }

    .module12 {
        background: #f4f5f5;
        height: 730px;

        .carousel_box {
            // height: 400px;
            margin-top: 50px;

            .el_carousel_cont {
                .list {
                    width: 170px;
                    height: 80px;
                    margin-left: 20px;
                    border: 1px solid #dedede;
                    margin-bottom: 20px;

                    img {
                        width: 100%;
                        height: 78px;
                    }
                }

                .list:nth-child(6n + 1) {
                    margin-left: 0px !important;
                }
            }

            ::v-deep.el-car-item {
                width: 100%;
                display: flex;
            }

            ::v-deep .el-carousel__arrow {
                display: none !important;
            }

            ::v-deep.el-carousel__indicators {
                padding-top: 30px;

                .el-carousel__button {
                    width: 10px;
                    height: 10px;
                    opacity: 1;
                    border-radius: 50%;
                    background: #5b5b5b;
                }

                .el-carousel__indicator.is-active button {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: #e62129;
                }
            }

            ::v-deep.el-carousel {
                .el-carousel__item {
                    padding-left: 70px;
                }

                .el-carousel__container {
                    height: 450px;

                    .el-carousel__arrow {
                        width: 60px;
                        height: 60px;
                        font-size: 24px;
                        background: rgba(51, 51, 51, 0.5);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }

    .make_appointment_content {
        width: 500px;
        height: 432px;
        background: #ffffff;
        border-radius: 20px;
        position: fixed;
        top: 50%;
        left: 50%;
        margin-top: -250px;
        margin-left: -216px;
        z-index: 1001;

        .icon_close {
            width: 44px;
            height: 44px;
            background: white;
            position: absolute;
            top: -10px;
            right: -10px;
            border-radius: 50%;
        }

        .title {
            width: 500px;
            height: 62px;
            border-radius: 20px 20px 0 0;
            background: #e62129;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
        }

        .main {
            width: 369px;
            padding: 30px;
            box-sizing: border-box;

            .user_phone {}

            .list {
                width: 437px;
                height: 46px;
                margin-bottom: 18px;

                input {
                    width: 430px;
                    height: 44px;
                    background: #f5f5f5;
                    font-size: 16px;
                    padding-left: 20px;
                }

                .min_input {
                    width: 192px;
                    height: 44px;
                    background: #f5f5f5;
                    font-size: 16px;
                    padding-left: 20px;
                }

                ::v-deep.el-input__inner {
                    width: 436px;
                    height: 44px;
                    background: #f5f5f5;
                    border: none;
                    border-radius: 0;
                }
            }

            .btn {
                width: 437px;
                height: 50px;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                background: #e62129;
            }
        }
    }

    .videoList_box {
        .classify_box {
            margin-top: 34px;

            .classify_list {
                width: 126px;
                height: 20px;
                font-size: 16px;
                color: #333333;
                border-right: 1px solid #dedede;
            }

            .classify_list:last-child {
                border-right: none !important;
            }

            .classifyActive {
                color: #e62129 !important;
            }
        }

        .video_mian {
            margin-top: 30px;
        }
    }

    .video_mask_layer {
        .mask_layer {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.48);
            position: fixed;
            top: 0px;
            left: 0px;
            z-index: 1000000;
        }

        .video_cont {
            width: 1100px;
            height: 700px;
            background: white;
            position: fixed;
            top: 50%;
            left: 50%;
            margin-top: -350px;
            margin-left: -550px;
            z-index: 1000001;
            padding: 40px;
            box-sizing: border-box;

            .videoCont {
                width: 100%;
                height: 100%;
            }

            .offBtn {
                width: 25px;
                height: 25px;
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }

    .navBar {
        margin-top: 29px;

        .navBar_list {
            width: 124px;
            height: 46px;
            background: #ffffff;
            border: 1px solid #dedede;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin: 0px 10px;
        }

        .navBarActive {
            background: #e62129 !important;
            border: 1px solid #e62129 !important;
            color: #fff !important;
        }
    }

    .video_div {
        position: relative;

        .off {
            width: 40px;
            height: 40px;
            position: absolute;
            top: 43px;
            right: 0;
            z-index: 2;
        }
    }
}
</style>
<style scoped>
.swiper-button-prev {
    top: 22px;
    left: 0px;
    width: 30px;
    height: 200px;
    background: rgba(0, 0, 0, 0.7);
}

.swiper-button-next {
    top: 22px;
    right: 0px;
    width: 30px;
    height: 200px;
    background: rgba(0, 0, 0, 0.7);
}

.swiper-button-prev:after,
.swiper-button-next:after {
    content: none;
}

.icon_left_right {
    width: 11px;
    height: 21px;
}
</style>